<template>
  <section id="hero-pro">
    <base-img
      :min-height="minHeight"
      :src="getBackgroundPicture()"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <br>
          <base-heading
            size="text-h2"
            title="KIDIWI Services"
            weight="medium"
          />
          <base-subheading
            weight="regular"
            :title="$t('heroPro.line1')"
          />
          <!--  <p>{{ $t('topmenu.professional') }}</p> -->
          <p>{{ $t('heroPro.line2') }}</p>
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <kdw-btn
              id="discover"
              outlined
              color="white"
              href="#Discover"
            >
              {{ $t('button.moreInfo') }}
            </kdw-btn>

            <span
              class="font-weight-bold ml-6 mr-6 my-4"
            >
              {{ $t('heroPro.or') }}
            </span>

            <kdw-btn
              id="accountCreation"
              @click="openConnexion()"
            >
              {{ $t('button.createAccountInfo') }}
            </kdw-btn>
          </div>
          <br>
          <!-- For desktop -->
          <div class="hidden-sm-and-down">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/NaLf3QcxJZE"
              frameborder="0"
              allow="accelerometer;autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <!-- For Mobile -->
          <div class="hidden-md-and-up">
            <iframe
              width="280"
              height="157"
              src="https://www.youtube.com/embed/NaLf3QcxJZE"
              frameborder="0"
              allow="accelerometer;autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </v-responsive>
      </v-container>
    </base-img>
  </section>
</template>

<script>
  import { navigate } from '@/helpers/Navigation'
  import { getBackgroundPicture } from '@/helpers/Background'

  export default {
    name: 'SectionHeroPro',

    provide: {
      theme: { isDark: false },
    },

    data: () => {
      return {
        backgroundPictureReference: '',
        youtubeURL: 'https://www.youtube.com/watch?v=NaLf3QcxJZE',
        result: '',
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    /*
    created () {
      this.loadURL()
    },
    */
    methods: {
      openConnexion () {
        navigate(this, 'PLogin')
      },
      getBackgroundPicture () {
        if (this.backgroundPictureReference !== '') return this.backgroundPictureReference
        this.backgroundPictureReference = getBackgroundPicture()
        return this.backgroundPictureReference
      },
      /*
      loadURL () {
        const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
        const url = this.youtubeURL.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        console.log('url', url)
        const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
        console.log('YId', YId)
        if (YId === url[0]) {
          console.log('not a youtube link')
        } else {
          console.log('its a youtube video')
        }
        const topOfQueue = youtubeEmbedTemplate.concat(YId)
        console.log('topOfQueue', topOfQueue)
        this.result = topOfQueue
        this.youtubeURL = ''
      },
      */
    },
  }

</script>
